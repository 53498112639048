<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push('/app/account')">Back</sc-button>

        </div>
        <div class="pd">
            <sc-form :form="form" @submit="form.post()" v-if="form">

                <template #field-old_password>
                    <sc-form-field :form="form" field="old_password" required>
                        <input v-model="form.fields.old_password" type="password" required/>
                    </sc-form-field>
                </template>

                <template #field-password>
                    <sc-form-field :form="form" field="password" required>
                        <input v-model="form.fields.password" type="password" required/>
                    </sc-form-field>
                </template>

                <template #field-password_confirmation>
                    <sc-form-field :form="form" field="password_confirmation" required>
                        <input v-model="form.fields.password_confirmation" type="password" required/>
                    </sc-form-field>
                </template>

            </sc-form>
        </div>

    </div>
</template>

<script>
export default {
  name: "pw-reset",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `Change Password`,
      url: `/users/profile/change-password`,
      fields: {
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      success: () => {
        this.$notify.success('Password changed');
        this.$router.push('/app/account');
      }
    })
  },

}
</script>

<style scoped lang="scss">

</style>